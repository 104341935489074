"use client";

import { Box } from "@mantine/core";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";

const MobileHero = dynamic(() => import("./hero.mobile"), {
  loading: () => <p>Loading...</p>,
  ssr: false,
});
const DesktopHero = dynamic(() => import("./hero.desktop"), {
  loading: () => <p>Loading...</p>,
  ssr: false,
});
const FeatureMarquee = dynamic(() => import("./marquee.jsx"), {
  loading: () => <p>Loading...</p>,
  ssr: false,
});
const Contact = dynamic(() => import("./contact"), {
  loading: () => <p>Loading...</p>,
  ssr: false,
});
const FeaturesCards = dynamic(() => import("./features"), {
  loading: () => <p>Loading...</p>,
  ssr: false,
});

export default function Hero() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Box>{isMobile ? <MobileHero /> : <DesktopHero />}</Box>

      <FeatureMarquee />
      <FeaturesCards />
      <Contact />
    </>
  );
}
